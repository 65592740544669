import { ReactNode } from 'react';

/**
 * Transforms incoming selected values for react-select such that if there's one or more selected
 * value referencing an option that has multiple values in the form of an array, then the selected
 * value would gain the same reference for the values as the option.
 *
 * E.g. if the options are:
 * [{label: 'label1', value: ['value1_1', 'value1_2']}, {label: 'label2', value: 'value2'}],
 * and the selected values are ['value1_1', 'value1_2', 'value2'], then the result will be:
 * [['value1_1', 'value1_2'], 'value2'], with the first array having the exact same reference as
 * the value in the option.
 *
 * @param options
 * @param selectedValues
 */
export function unifyMultiSelectedValues(
  options: Array<{ label: ReactNode; value: any }>,
  selectedValues: Array<any>
) {
  const optionsWrappedBySplitValue: Map<any, OptionValueWrapped[]> = new Map();
  for (const option of options) {
    const values = Array.isArray(option.value) ? option.value : [option.value];
    const valuesRemaining = new Set(values);
    for (const splitValue of values) {
      const optionValuesWrapped =
        optionsWrappedBySplitValue.get(splitValue) ??
        optionsWrappedBySplitValue.set(splitValue, []).get(splitValue)!;
      optionValuesWrapped.push({ optionValue: option.value, valuesRemaining });
    }
  }

  const selectedValuesWithReferencesLeft: Array<{ value: any; referencesLeft: Set<any> }> = [];
  const usedOptionValues: Set<any> = new Set();

  const selectedValuesFlat = selectedValues
    .map(value => (Array.isArray(value) ? value : [value]))
    .flat();

  for (const value of selectedValuesFlat) {
    const optionValuesWrapped = optionsWrappedBySplitValue.get(value);
    if (!optionValuesWrapped) {
      selectedValuesWithReferencesLeft.push({ value, referencesLeft: new Set() });
      usedOptionValues.add(value);
      continue;
    }
    for (const optionValueWrapped of optionValuesWrapped) {
      if (!usedOptionValues.has(optionValueWrapped.optionValue)) {
        selectedValuesWithReferencesLeft.push({
          value: optionValueWrapped.optionValue,
          referencesLeft: optionValueWrapped.valuesRemaining,
        });
        usedOptionValues.add(optionValueWrapped.optionValue);
      }
      if (optionValueWrapped.valuesRemaining.has(value)) {
        optionValueWrapped.valuesRemaining.delete(value);
      }
    }
  }

  //return selectedValuesWithReferencesLeft.filter(v => !v.referencesLeft.size).map(v => v.value);
  return selectedValuesWithReferencesLeft
    .map(object =>
      object.referencesLeft.size && Array.isArray(object.value)
        ? object.value.filter(v => !object.referencesLeft.has(v))
        : [object.value]
    )
    .flat();
}

type OptionValueWrapped = { optionValue: any; valuesRemaining: Set<any> };
