import { useMemo } from 'react';
import { industryUuid } from '../../values/apiFields';
import { formatFieldOption, splitSectors } from '../fields';
import { groupBy, mapValues } from 'lodash-es';
import { FieldOptionDefinition } from '../../store/reducers/fieldsReducer';

export default function(
  fieldUuid: string,
  options: (FieldOptionDefinition | { value: string; label: string })[]
) {
  const splitOptions = useMemo(() => {
    return fieldUuid === industryUuid
      ? splitSectors(
          (options as FieldOptionDefinition[]).map(option =>
            option.name === 'Consumer/Internet'
              ? { ...option, name: 'TMT/Consumer/Internet' }
              : option
          )
        )
      : options;
  }, [fieldUuid, options]);

  return useMemo(() => {
    return Object.values(
      mapValues(
        groupBy(splitOptions, v => ('name' in v ? v.name : v.label)),
        multipleOptions => {
          return {
            label: formatFieldOption(multipleOptions[0]),
            value: multipleOptions.map(v => ('uuid' in v ? v.uuid : v.value)),
          };
        }
      )
    );
  }, [splitOptions]);
}
