import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { array, func, oneOf, string } from 'prop-types';
import React, { useCallback, useMemo, useRef } from 'react';
import useFieldDisplayOptions from '../../utils/hooks/useFieldDisplayOptions';
import { apiFieldTypes } from '../../values/apiFields';
import Select from '../UI/Select/Select';
import { unifyMultiSelectedValues } from '../UI/Select/selectHelper';

const Dropdown = styled(Select)`
  margin-bottom: 0.5rem;
`;

function FilterField({
  uuid,
  name,
  options = [],
  value = [],
  onChangeSimple = () => {},
  ...restProps
}) {
  const el = useRef(null);
  const theme = useTheme();

  // Makes sure that when called with an array of a mixture of scalar and array values,
  // it ensures it's an array of scalar values.
  const onChangeSimpleFlatten = useCallback(
    values => onChangeSimple(values.map(v => (Array.isArray(v) ? v : [])).flat()),
    [onChangeSimple]
  );

  const styles = {
    control: provided => ({
      ...provided,
      borderRadius: 0,
      ...(theme.name === 'tne'
        ? {
            backgroundColor: 'rgba(255, 255, 255, 0)',
            borderColor: '#555',
            cursor: 'pointer',
          }
        : {}),
    }),
    indicatorSeparator: provided => ({
      ...provided,
      ...(theme.name === 'tne'
        ? {
            display: 'none',
          }
        : {}),
    }),
  };

  const displayOptions = useFieldDisplayOptions(uuid, options);

  const valueUnified = useMemo(() => {
    return unifyMultiSelectedValues(displayOptions, value);
  }, [displayOptions, value]);

  return (
    <div ref={el} {...restProps}>
      <Dropdown
        styles={styles}
        placeholder={name}
        isMulti
        options={displayOptions}
        value={valueUnified}
        onChange={onChangeSimpleFlatten}
      />
    </div>
  );
}

FilterField.propTypes = {
  name: string,
  type: oneOf(apiFieldTypes),
  options: array,
  uuid: string,
  onChangeSimple: func,
  value: array,
};

export default FilterField;
