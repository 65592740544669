import React from 'react';
import { FieldOption, FieldOptionChange } from '../types/entities';
import { formatISO } from 'date-fns';
import { findLast } from 'lodash-es';
import { FieldOptionDefinition } from '../store/reducers/fieldsReducer';

export function isFieldOptionExpired(
  fieldOption: { changes?: FieldOptionChange[] },
  date?: string
) {
  const compareDate = date?.substring(0, 10) || formatISO(new Date(), { representation: 'date' });

  let expiresAt = fieldOption.changes?.find(v => v.name === null)?.startsAt;
  return expiresAt && expiresAt <= compareDate;
}

export function formatFieldOption(
  fieldOption: FieldOption | FieldOptionDefinition | { label: string },
  date?: string
) {
  let name: string = '';
  if ('option' in fieldOption) {
    name = fieldOption.option;
  } else if ('name' in fieldOption) {
    name = fieldOption.name;
  } else {
    // Just a plain label-value that should not be formatted specially.
    return fieldOption.label;
  }

  const compareDate = date?.substring(0, 10) || formatISO(new Date(), { representation: 'date' });

  const lastNameChange = findLast(
    ('changes' in fieldOption ? fieldOption.changes : []) ?? [],
    change => change.name !== null && change.startsAt <= compareDate
  );

  const symbol = lastNameChange?.name ?? name;

  return isFieldOptionExpired(fieldOption, date) ? <del>{symbol}</del> : symbol;
}

/**
 * Formats a field option, but showing all of its (historic) names too.
 */
export function formatFieldOptionAllNames(
  fieldOption: FieldOption | FieldOptionDefinition,
  date?: string
) {
  const displayName = [
    'option' in fieldOption ? fieldOption.option : fieldOption.name,
    ...(fieldOption?.changes ?? []).filter(change => change.name !== null).map(v => v.name),
  ].join(' ➔ ');

  if (isFieldOptionExpired(fieldOption, date)) {
    return <del>{displayName}</del>;
  }

  return displayName;
}

export function splitSectors(options: FieldOptionDefinition[]): FieldOptionDefinition[] {
  const optionsBySimpleName: Record<string, FieldOptionDefinition> = {};
  const newOptions: typeof options = [];

  for (const option of options) {
    const splitOptionNames = option.name.split('/');

    for (const splitOptionName of splitOptionNames) {
      const splitOption = { ...option, name: splitOptionName };
      optionsBySimpleName[splitOptionName] = splitOption;
      newOptions.push(splitOption);
    }
  }

  return newOptions;
}
